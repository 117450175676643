import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { FunctionComponent, ReactNode } from "react";

type Props = {
  children: ReactNode[] | ReactNode;
  title: string;
};

const maxHeight = "250px";

export const Tile: FunctionComponent<Props> = ({ children, title }) => {
  const width = useBreakpointValue({ base: "100%", lg: "350px" });
  return (
    <Box
      borderRadius={{ base: "none", md: "sm" }}
      borderX={{ base: "none", md: "1px" }}
      borderY="1px"
      w={width}
      my="10px"
      mx={{ base: "none", md: "10px" }}
      flexDirection="column"
      overflow="auto"
      bg="white"
      borderColor="blue.800"
      boxShadow="xl"
    >
      <Text
        color="white"
        fontWeight="bold"
        bg="blue.400"
        p="10px"
        overflow="hidden"
      >
        {title}
      </Text>
      <Flex p="10px" flexDirection="column">
        {children}
      </Flex>
    </Box>
  );
};

import { Box, Flex } from "@chakra-ui/react";
import SchoolBckImg from "../src/assets/images/schoolbackground.png";
import "./App.css";
import { Menu } from "./header/menu.component";
import { Realisation } from "./realisation/realisation.component";
import { Documents } from "./tile/documents.component";
import { Hours } from "./tile/hours.component";
import { RecreActionTile } from "./tile/recreaction.component";
import { UsefullLinks } from "./tile/usefull-links.component";

function App() {
  return (
    <>
      <Menu />
      <Box
        flex="1"
        backgroundImage={SchoolBckImg}
        overflow="auto"
        py={{ base: "15vh" }}
      >
        <Realisation />
        <Flex maxW="1200px" flexWrap="wrap" margin="auto">
          <Hours />
          <RecreActionTile />
          <UsefullLinks />
          <Documents />
        </Flex>
      </Box>
      <Box h="50px" display="none">
        Footer
      </Box>
    </>
  );
}

export default App;

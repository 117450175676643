import { EmailIcon } from "@chakra-ui/icons";
import { Avatar, Box, Flex, Image, Link } from "@chakra-ui/react";
import { FC } from "react";
import blackBoardImg from "../assets/images/black-board.jpg";
import FBImg from "../assets/images/fb.png";
import funnyKidImg from "../assets/images/funnykid.png";
import { ApecmCamelCase } from "../shared/apecmCamelCase.component";

export const Menu: FC = () => {
  return (
    <Flex
      backgroundImage={blackBoardImg}
      alignItems={{ base: "flex-end", md: "unset" }}
      position="fixed"
      zIndex={1000}
      w="100vw"
    >
      <Image
        float="left"
        h={{ base: "7vh", md: "15vh" }}
        maxW="200px"
        src={funnyKidImg}
      />

      <Flex
        flex="1"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
        px="5px"
        pt="5px"
      >
        <Flex flex="1">
          <Box w="100%" color="whiteAlpha.700">
            <ApecmCamelCase /> vous souhaite la bienvenue
          </Box>
        </Flex>
        <Box
          textAlign="right"
          p="5px"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Link
            href="https://www.facebook.com/apecm.ecoledemarcq"
            mr={{ base: "0", md: "10px" }}
            mb={{ base: "10px", md: "0" }}
            isExternal
          >
            <Avatar src={FBImg} size={{ base: "sm", md: "md" }} />
          </Link>

          <Link
            href="mailto:apecmarcq@gmail.com"
            isExternal
            mb={{ base: "10px", md: "0" }}
          >
            <Avatar
              size={{ base: "sm", md: "md" }}
              icon={
                <EmailIcon
                  h={{ base: "20px", md: "30px" }}
                  w={{ base: "20px", md: "30px" }}
                  color="white"
                />
              }
              bg="green.600"
            />
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
};

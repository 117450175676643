import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Image, Link, useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
import recreAction from "../assets/images/recreAction.png";
import { Tile } from "../shared/tile.component";

export const RecreActionTile: FC = () => {
  const width = useBreakpointValue({ base: "100%", lg: "250px" });
  return (
    <Tile title="Acceuil Extra scolaire">
      <Box as="p" fontSize="md">
        <Image
          w="25%"
          h="auto"
          src={recreAction}
          alt="Recreaction"
          position="relative"
          float="left"
          mr="15px"
        />
        <Box fontWeight="bold" mb="5px">
          Tous les jours de 6h30 à 18h30
        </Box>
        L’équipe d’accueillant-e-s extrascolaires de{" "}
        <Link
          isExternal
          fontWeight="semibold"
          textDecoration="underline"
          textColor="blue.700"
          href="https://www.facebook.com/pages/category/Local-service/ASBL-R%C3%A9cr%C3%A9action-JG-Enghien-accueil-extrascolaire-2258400337735188/"
        >
          RécréAction J.G.
          <ExternalLinkIcon mx="2px" />
        </Link>{" "}
        propose d’accueillir vos enfants tous les jours de la semaine avant le
        début des cours dès 6h30 et après la fin des cours jusque 18h30. Ils
        sont aussi présents pour participer avec l’équipe enseignante à
        l’organisation du temps de midi.
      </Box>
    </Tile>
  );
};

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
// @ts-ignore
import ReformePdf from "../assets/files/reforme.pdf";
// @ts-ignore
import projetEtablissementPdf from "../assets/files/projet_etablissement.pdf";
// @ts-ignore
import JustificatifAbsencePdf from "../assets/files/justificatif_absence.pdf";
// @ts-ignore
import FicheMedicalePdf from "../assets/files/fiche_medicale.pdf";

import { Tile } from "../shared/tile.component";

export const Documents: FC = () => {
  const width = useBreakpointValue({ base: "100%", lg: "250px" });
  return (
    <Tile title="Documents">
      <Link
        isExternal
        py="5px"
        textDecoration="underline"
        color="blue.600"
        href={ReformePdf}
      >
        Réforme calendrier scolaire <ExternalLinkIcon mx="2px" />
      </Link>
      <Link
        isExternal
        py="5px"
        textDecoration="underline"
        color="blue.600"
        href={projetEtablissementPdf}
      >
        Projet d'établissement <ExternalLinkIcon mx="2px" />
      </Link>
      <Link
        isExternal
        py="5px"
        textDecoration="underline"
        color="blue.600"
        href={JustificatifAbsencePdf}
      >
        Justificatif absence <ExternalLinkIcon mx="2px" />
      </Link>
      <Link
        isExternal
        py="5px"
        textDecoration="underline"
        color="blue.600"
        href={FicheMedicalePdf}
      >
        Fiche médicale <ExternalLinkIcon mx="2px" />
      </Link>
    </Tile>
  );
};

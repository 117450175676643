import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  UnorderedList
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
// @ts-ignore
import ApecmPresentation from "../assets/files/apecm_presentation.pdf";
import ApecmImg from "../assets/images/apecm.jpg";

type Props = {};

const ApecmSpan: FunctionComponent = () => (
  <>
    <Box as="span" color="red" fontWeight="extrabold">
      A
    </Box>
    <Box as="span" color="blue.400" fontWeight="extrabold">
      P
    </Box>
    <Box as="span" color={"#fc03c6"} fontWeight="extrabold">
      E
    </Box>
    <Box as="span" color={"#c76408"} fontWeight="extrabold">
      c
    </Box>
    <Box as="span" color={"#075e35"} fontWeight="extrabold">
      M
    </Box>
  </>
);

const MoreAboutApecm: FunctionComponent = () => (
  <Box w="100%" textAlign="center" mb="10px">
    <Button colorScheme="gray">
      <Link
        fontSize="lg"
        isExternal
        w="100%"
        textDecoration="underline"
        fontWeight="semibold"
        href={ApecmPresentation}
      >
        En savoir plus sur l'
        <ApecmSpan /> <ExternalLinkIcon />
      </Link>
    </Button>
  </Box>
);

export const Realisation: FunctionComponent<Props> = () => {
  return (
    <Box
      maxW="1200px"
      mx="auto"
      my="10px"
      borderTop="1px"
      borderBottom="1px"
      borderLeft={{ base: "none", md: "1px" }}
      borderRight={{ base: "none", md: "1px" }}
      bg="white"
    >
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        borderRadius={{ base: "none", md: "10px" }}
        overflow="hidden"
        h={{ base: "unset", md: "250px" }}
        maxH={{ base: "unset", md: "250px" }}
      >
        <Flex
          h={{ base: "unset", md: "100%" }}
          flexDirection="row"
          alignItems="center"
          p="10px"
        >
          <Image
            alignSelf="center"
            src={ApecmImg}
            w={{ base: "100vw", sm: "300px" }}
            h="auto"
            maxW={{ base: "unset", sm: "300px" }}
            margin="auto"
          />
        </Flex>
        <Box flex="1" p="5px" color="gray.700">
          <Tabs isFitted>
            <TabList fontSize="sm">
              <Tab>Projets</Tab>
              <Tab>Communication</Tab>
              <Tab>Ecole</Tab>
            </TabList>

            <TabPanels h={{ base: "300px", md: "unset" }}>
              <TabPanel>
                L'
                <ApecmSpan /> organise des activités visant à soutenir des
                projets à vocation:
                <UnorderedList>
                  <ListItem>
                    <u>sociale</u>: aide financiaire et soutien aux parents en
                    difficultés
                  </ListItem>
                  <ListItem>
                    <u>scolaire</u>: achat de matériel pour améliorer la vie de
                    l'école, ...
                  </ListItem>
                  <ListItem>
                    <u>parascolaire</u>: activités durant les congés
                    pédagogiques, ...
                  </ListItem>
                  <ListItem>
                    <u>éducative</u>: organisation de conférences, ...{" "}
                  </ListItem>
                  <ListItem>
                    <u>festive</u>: organisation du souper fromage, ...
                  </ListItem>
                </UnorderedList>
              </TabPanel>
              <TabPanel>
                L'
                <ApecmSpan /> joue le rôle de relais entre les parents et
                l'école. C'est aussi l'endroit où les parents peuvent échanger
                entre eux au sujet de tout ce qui touche à l'école. L'accent est
                mis sur le dialogue et l'ouverture. Les thèmes exclus sont: les
                discussions portant sur la pédagogie et les convictions
                politiques et idéologiques.
              </TabPanel>
              <TabPanel>
                L'
                <ApecmSpan /> représente l'ensemble des parents devant les
                responsables de l'école (direction et PO), mais aussi au sein de
                différentes instances institutionnelles (conseil de
                participation, Comité d'accompagnement et Commission communale
                d'accueil).
                <br /> <br />
                L'
                <ApecmSpan />, outre les projets qu'elle initie elle-même,
                apporte son soutien aux projets portés par l'école.
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
      <MoreAboutApecm />
    </Box>
  );
};

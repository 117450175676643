import { Box } from "@chakra-ui/react";
import { FC } from "react";

export const ApecmCamelCase: FC = () => {
  return (
    <Box as="span" display="inline-block">
      <Box as="span" color="red" fontWeight="bold">
        A
      </Box>
      ssociation des{" "}
      <Box as="span" color="blue.400" fontWeight="bold">
        P
      </Box>
      arents d'
      <Box as="span" color={"#fc03c6"} fontWeight="bold">
        E
      </Box>
      lèves de l'école{" "}
      <Box as="span" color={"#f9a75a"} fontWeight="bold">
        c
      </Box>
      ommunale de{" "}
      <Box as="span" color={"#26d281"} fontWeight="bold">
        M
      </Box>
      arcq
    </Box>
  );
};

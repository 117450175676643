import { Table, Td, Tr, useBreakpointValue } from "@chakra-ui/react";
import { FC } from "react";
import { Tile } from "../shared/tile.component";

export const Hours: FC = () => {
  const width = useBreakpointValue({ base: "100%", lg: "250px" });
  return (
    <Tile title="Horaires">
      <Table fontSize="xs">
        <Tr>
          <Td px="5px" py="15px">
            Lundi
          </Td>
          <Td px="5px" py="15px">
            8h20 - 12h10
          </Td>
          <Td px="5px" py="15px">
            13h25 - 15h20
          </Td>
        </Tr>
        <Tr>
          <Td px="5px" py="15px">
            Mardi
          </Td>
          <Td px="5px" py="15px">
            8h20 - 12h10
          </Td>
          <Td px="5px" py="15px">
            13h25 - 15h20
          </Td>
        </Tr>
        <Tr>
          <Td px="5px" py="15px">
            Mercredi
          </Td>
          <Td px="5px" py="15px">
            8h20 - 12h10
          </Td>
          <Td px="5px" py="15px">
            &nbsp;
          </Td>
        </Tr>
        <Tr>
          <Td px="5px" py="15px">
            Jeudi
          </Td>
          <Td px="5px" py="15px">
            8h20 - 12h10
          </Td>
          <Td px="5px" py="15px">
            13h25 - 15h20
          </Td>
        </Tr>
        <Tr>
          <Td px="5px" py="15px">
            Vendredi
          </Td>
          <Td px="5px" py="15px">
            8h20 - 12h10
          </Td>
          <Td px="5px" py="15px">
            13h25 - 15h05
          </Td>
        </Tr>
      </Table>
    </Tile>
  );
};

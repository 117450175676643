import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { FC } from "react";
//@ts-ignore
//@ts-ignore
//@ts-ignore
import MenuPdf from "../assets/files/menu_09_2022.pdf";

import MenuImg from "../assets/images/menu_resto.png";

import { Tile } from "../shared/tile.component";

export const UsefullLinks: FC = () => {
  return (
    <Tile title="Infos utiles">
      {/** 
      <Flex flexDirection="row" w="100%" mb="10px">
        <Image src={SchoolBusImg} h="70px" w="90px" />
        <Box as="span" flex="1" margin="auto" textAlign="center">
          <Link
            isExternal
            color="blue.600"
            textDecoration="underline"
            href={SortieScolaire202206Pdf}
          >
            Sorties scolaires Juin
            <ExternalLinkIcon ml="5px" />
          </Link>
        </Box>
      </Flex>
      <Divider />
      <Flex flexDirection="row" w="100%" my="10px">
        <Image src={PoolImg} h="70px" w="90px" />
        <Box as="span" flex="1" margin="auto" textAlign="center">
          <Link
            isExternal
            color="blue.600"
            textDecoration="underline"
            href={PoolSchedulePdf}
          >
            Horaire Piscine
            <ExternalLinkIcon ml="5px" />
          </Link>
        </Box>
      </Flex>
      <Divider />
      */}
      <Flex flexDirection="row" w="100%" mt="10px">
        <Image src={MenuImg} h="70px" w="90px" />
        <Box as="span" flex="1" margin="auto" textAlign="center">
          <Link
            isExternal
            color="blue.600"
            textDecoration="underline"
            href={MenuPdf}
          >
            Menu Septembre
            <ExternalLinkIcon ml="5px" />
          </Link>
        </Box>
      </Flex>
    </Tile>
  );
};
